import { TextField } from "@shopify/polaris";
import React from "react";

import { polarisToNativeHandler } from "../helpers";

export interface TextAreaElement
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  label: string;
  suffix?: string;
  error?: string;
  usePolaris?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TextAreaElement<T>({
  id,
  label,
  value,
  placeholder,
  error,
  name,
  onChange,
  onBlur,
  disabled,
  suffix,
  usePolaris,
}: TextAreaElement): JSX.Element {
  const commonProps = {
    id,
    value: value !== undefined && value !== null ? value.toString() : undefined,
    placeholder,
    name,
    disabled,
  };

  if (usePolaris) {
    return (
      <TextField
        autoComplete="off"
        {...commonProps}
        multiline
        label={label}
        error={error}
        onChange={polarisToNativeHandler<React.ChangeEvent<HTMLTextAreaElement>>(name, onChange)}
        onBlur={onBlur}
        suffix={suffix}
      />
    );
  }

  return <textarea {...commonProps} onChange={onChange} onBlur={onBlur} />;
}
