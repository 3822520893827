import { Checkbox, TextField } from "@shopify/polaris";
import React from "react";

import { polarisToNativeHandler } from "../helpers";

export interface InputElementProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: string;
  suffix?: string;
  error?: string;
  usePolaris?: boolean;
}

function CheckboxElement({
  id,
  name,
  type,
  label,
  checked,
  onChange,
  onBlur,
  error,
  disabled,
  usePolaris,
}: InputElementProps): JSX.Element {
  if (usePolaris) {
    return (
      <Checkbox
        id={id}
        name={name}
        label={label}
        checked={checked}
        onChange={polarisToNativeHandler<React.ChangeEvent<HTMLInputElement>>(name, onChange)}
        onBlur={polarisToNativeHandler<React.FocusEvent<HTMLInputElement>>(name, onBlur)}
        error={error}
        disabled={disabled}
      />
    );
  }

  return (
    <input
      type={type}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function InputTextElement<T>({
  id,
  type,
  label,
  value,
  placeholder,
  error,
  name,
  onChange,
  onBlur,
  disabled,
  suffix,
  min,
  max,
  usePolaris,
}: InputElementProps): JSX.Element {
  const commonProps = {
    id,
    value: value !== undefined && value !== null ? value.toString() : undefined,
    placeholder,
    name,
    disabled,
    min,
    max,
  };

  if (usePolaris) {
    return (
      <TextField
        autoComplete="off"
        {...commonProps}
        type={type as any} // Polaris doesn't export union of types
        label={label}
        error={error}
        onChange={polarisToNativeHandler<React.ChangeEvent<HTMLInputElement>>(name, onChange)}
        onBlur={onBlur}
        suffix={suffix}
      />
    );
  }

  return <input {...commonProps} type={type} onChange={onChange} onBlur={onBlur} />;
}

export function InputElement({ type, ...props }: InputElementProps): JSX.Element {
  return type === "checkbox" ? (
    <CheckboxElement type={type} {...props} />
  ) : (
    <InputTextElement type={type} {...props} />
  );
}
