import { SyntheticEvent, useCallback } from "react";

export function polarisToNativeHandler<EventType extends SyntheticEvent>(
  name: string | undefined,
  handler?: (e: EventType) => void
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(
    (newValue?: string | number | boolean) =>
      handler &&
      handler({
        target: { name, value: newValue },
        currentTarget: { name, value: newValue },
      } as unknown as EventType),
    [handler, name]
  );
}
