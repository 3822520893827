import { Switch } from "@mui/material";
import { ChoiceList, ChoiceListProps } from "@shopify/polaris";
import React, { InputHTMLAttributes, useMemo } from "react";

import { ToggleSwitch } from "../../ToggleSwitch/ToggleSwitch";
import { IRadioButton } from "../useTypedForm";

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  className?: string;
  label?: string;
  buttons: IRadioButton<string>[];
  value: string;
  usePolaris?: boolean;
  useToggle?: boolean;
  useMui?: boolean;
  align: "right" | "center" | "left";
  onToggle?: () => Promise<void>;
  onChange: (newValue: any[], _: any) => void;
}

export function RadioElement({
  className,
  label,
  buttons,
  usePolaris,
  useToggle,
  useMui,
  onToggle,
  align,
  onChange,
  value,
  disabled,
  ...props
}: Props): JSX.Element {
  const choices: ChoiceListProps["choices"] = useMemo(() => buttons, []);
  if (useToggle) {
    return (
      <div style={{ display: "flex", justifyContent: align }}>
        <ToggleSwitch
          disabled={disabled}
          onClick={value => {
            onChange([value], null);
            // onToggle!();
          }}
          toggled={!!value}
        />
      </div>
    );
  }
  if (useMui) {
    return (
      <Switch
        value={value}
        onChange={e => onChange([e.target.checked], undefined)}
        defaultChecked={!!value}
        disabled={disabled}
        color="primary"
      />
    );
  }
  if (usePolaris) {
    return (
      <div className={className}>
        <ChoiceList
          title={label || ""}
          choices={choices}
          selected={[value]}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      {buttons.map(button => (
        <div key={button.label}>
          <label>
            <input
              type="radio"
              disabled={button.disabled || disabled}
              name={props.name}
              value={button.value}
              onChange={e => e.target.checked && onChange([e.target.value], undefined)}
            />
            <span>{button.label}</span>
          </label>
        </div>
      ))}
    </div>
  );
}
