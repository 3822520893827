const capitalLetterFollowedByLowercase = /([A-Z][a-z]+)/g;
const multipleCapitalLetter = /([A-Z]{2,})/g;
const multipleSpace = /\s{2,}/g;

export function camelCaseToSentence(word: string) {
  const sentenceCase = word
    .replaceAll(capitalLetterFollowedByLowercase, " $1")
    .replaceAll(multipleCapitalLetter, " $1")
    .replaceAll(multipleSpace, " ")
    .trim();

  capitalLetterFollowedByLowercase.lastIndex = 0;
  multipleCapitalLetter.lastIndex = 0;
  multipleSpace.lastIndex = 0;

  const firstCharacter = sentenceCase[0];
  const otherCharacters = sentenceCase.slice(1);

  return `${firstCharacter.toUpperCase()}${otherCharacters}`;
}
