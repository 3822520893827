/* eslint-disable react-hooks/rules-of-hooks */
import { Button, ButtonProps } from "@shopify/polaris";
import { YupValidation } from "@smartrr/shared/entities/shared/YupValidation";
import { camelCaseToSentence } from "@smartrr/shared/utils/camelCaseToSentence";
import { NestedSubType } from "@smartrr/shared/utils/NestedKeys";
import { PrimitiveSubType } from "@smartrr/shared/utils/PrimitiveKeys";
import cx from "classnames";
import { FormikConfig, FormikErrors, FormikTouched, useFormik } from "formik";
import { get } from "lodash";
import React, {
  ButtonHTMLAttributes,
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
//@ts-ignore
import { Action, Store, createStore } from "redux";

import { DropdownElement } from "./elements/Dropdown";
import { InputElement } from "./elements/Input";
import { RadioElement } from "./elements/Radio";
import { TextAreaElement } from "./elements/TextArea";

const numericRegexTest = /^\d+$/;

export interface TypedInputProps<T extends object, V extends keyof PrimitiveSubType<T> & string>
  extends InputHTMLAttributes<HTMLInputElement> {
  type: PrimitiveSubType<T>[V] extends boolean
    ? "checkbox"
    : PrimitiveSubType<T>[V] extends string
      ? "text" | "password" | "email" | "tel"
      : PrimitiveSubType<T>[V] extends number
        ? "number"
        : any;
  radioId?: PrimitiveSubType<T>[V] extends string ? PrimitiveSubType<T>[V] : never;
  label: string;
  usePolaris?: boolean;
  helpText?: string;
  suffix?: string;
  id?: never;
  name?: never;
  onChange?: never;
  value?: never;
  form?: never;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface TypedTextAreaProps<T extends object> extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  usePolaris?: boolean;
  id?: never;
  name?: never;
  onChange?: never;
  value?: never;
  form?: never;
}

export interface ISelectOption<T> {
  value: T;
  label?: string;
  disabled?: boolean;
}

export interface IRadioButton<T> {
  value: T;
  label: string;
  disabled?: boolean;
}

export interface TypedDropdownProps<T extends object, V extends keyof PrimitiveSubType<T>>
  extends SelectHTMLAttributes<HTMLSelectElement> {
  description: string;
  usePolaris?: boolean;
  label?: string;
  options: ISelectOption<PrimitiveSubType<T>[V]>[];
  form?: never;
  onChange?: never;
  value?: never;
  toolTip?: NonNullable<React.ReactNode>;
}

export interface TypedRadioProps<T extends object, V extends keyof PrimitiveSubType<T>> {
  name: string;
  usePolaris?: boolean;
  useToggle?: boolean;
  useMui?: boolean;
  align: "right" | "center" | "left";
  label?: string;
  className?: string;
  buttons: IRadioButton<PrimitiveSubType<T>[V]>[];
  value?: never;
  allowMultiple?: never;
  onToggle?: () => Promise<void>;
  disabled?: boolean;
}

export interface TypedLabelProps<T extends object, V extends keyof PrimitiveSubType<T> & string>
  extends LabelHTMLAttributes<HTMLLabelElement> {
  htmlFor?: never;
  radioId?: PrimitiveSubType<T>[V] extends string ? PrimitiveSubType<T>[V] : never;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ValidationErrorProps<T extends object, V extends keyof T & string>
  extends HTMLAttributes<HTMLDivElement> {
  children?: never;
}

interface FormConfigWithTypedValidationScheme<T extends object> extends FormikConfig<T> {
  validationSchema: YupValidation<T>;
  validate?: never;
  component?: never;
  render?: never;
  children?: never;
  innerRef?: never;
}

interface FormikStoreValues<T> {
  values: T & object;
  errors: FormikErrors<T> & object;
  touched: FormikTouched<T> & object;
}
const pseudoActionType = {}; // action type is required, just making random pointer to fulfill that requirement
interface FormikStoreAction<T> extends Action<typeof pseudoActionType>, FormikStoreValues<T> {}

export function useTypedForm<T extends object>(formikConfig: FormConfigWithTypedValidationScheme<T>) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    setFieldValue,
    validateForm,
    isValid,
    submitForm,
  } = useFormik<T>(formikConfig);

  // store needed to share state with fields
  const store: Store<FormikStoreValues<T>, FormikStoreAction<T>> = useMemo(() => {
    const initialValues: FormikStoreValues<T> = {
      values,
      errors,
      touched,
    };

    return createStore<FormikStoreValues<T>, FormikStoreAction<T>, never, never>(
      (
        __,
        {
          values: currentValues = initialValues.values,
          errors: currentErrors = initialValues.errors,
          touched: currentTouched = initialValues.touched,
        }
      ) => ({
        values: currentValues,
        errors: currentErrors,
        touched: currentTouched,
      }),
      initialValues as FormikStoreValues<T> as any // can't deal with odd type behavior around redux's "PreloadedState" type right now
    );
  }, []);

  // keep store in sync with form values
  useEffect(() => {
    store.dispatch({
      type: pseudoActionType,
      values,
      errors,
      touched,
    });
  }, [values, errors, touched]);

  const createUseField = useGenerateCreateUseField(store, handleBlur, handleChange, setFieldValue);

  // ideally might move this to separate function, not worth it right now
  const useSubForm = useCallback(
    <ContainerT extends object, V extends keyof NestedSubType<ContainerT> & string>(nestedField: V) => {
      type ContainerNestedSubType = NestedSubType<ContainerT>;
      type NestedPrimitives = PrimitiveSubType<ContainerNestedSubType[V]>;
      type NestedNested = NestedSubType<ContainerNestedSubType[V]>;

      const useNestedField = createUseField<NestedPrimitives>(nestedField);
      const useNestedSubForm = <
        NextNested extends NestedNested,
        NextNestedKey extends keyof NestedSubType<NextNested> & string,
      >(
        nextNested: NextNestedKey
      ) => {
        return useSubForm<NextNested, NextNestedKey>(
          // cheating here to accumulate string because typed nested key paths are impossible
          `${nestedField}.${nextNested}` as any
        );
      };

      const result: [typeof useNestedField, typeof useNestedSubForm] = [useNestedField, useNestedSubForm];

      return result;
    },
    [createUseField]
  );

  const baseUseSubForm = useCallback(
    <V extends keyof NestedSubType<T> & string>(nestedField: V) => useSubForm<T, V>(nestedField),
    [useSubForm]
  );

  const useValues = useCallback(() => {
    const [currentValues, setCurrentValues] = useState(values);
    useEffect(() => {
      let timeout: NodeJS.Timeout;
      // subscribing to redux returns unsubscribing function
      const unsubscribe = store.subscribe(() => {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
          const storeValues = store.getState().values;
          if (currentValues !== storeValues) {
            setCurrentValues(storeValues);
          }
        }, 0);
      });

      return () => {
        clearTimeout(timeout);
        unsubscribe();
      };
    }, []);

    return currentValues;
  }, []);

  const memo = useMemo(
    () => ({
      useValues,
      setFieldValue,
      validateForm,
      submitForm,
      useField: createUseField<T>(""),
      useSubForm: baseUseSubForm,
      handleReset,
      Submit: React.memo(({ children = "Submit", ...props }: ButtonProps) => {
        // event needs to be casted to "any", don't want to enforce wrapping with "form"
        const _handleSubmit = useCallback((e: any) => handleSubmit(e), []);

        return (
          <Button
            submit
            {...props}
            onClick={() =>
              _handleSubmit({
                target: {},
                currentTarget: {},
              } as React.MouseEvent<HTMLButtonElement>)
            }
          >
            {children}
          </Button>
        );
      }),
      Reset: React.memo(({ children = "Reset", ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => (
        <button type="reset" {...props} onClick={handleReset}>
          {children}
        </button>
      )),
    }),
    []
  );

  return {
    ...memo,
    isValid,
  };
}

// opportunity for better optimization later
function useGenerateCreateUseField<T extends object>(
  store: Store<FormikStoreValues<T>, FormikStoreAction<T>>,
  handleBlur: (e: React.FocusEvent<any>) => void, // not dealing with "any" here
  handleChange: (e: React.ChangeEvent<any>) => void, // not dealing with "any" here
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void // not dealing with "any" here
) {
  return useCallback(
    <SubT extends { [key: string]: any }>(
      prefixKeyPath: string // cheating with string type for "prefixKeyPath" as nested keypath can't be typed
    ) =>
      <V extends keyof PrimitiveSubType<SubT> & string>(field: V) =>
        useMemo((): {
          Input: React.MemoExoticComponent<(inputProps: TypedInputProps<SubT, V>) => JSX.Element>;
          TextArea: React.MemoExoticComponent<(inputProps: TypedTextAreaProps<SubT>) => JSX.Element>;
          Label: React.MemoExoticComponent<(labelProps: TypedLabelProps<SubT, V>) => JSX.Element>;
          ValidationError: React.MemoExoticComponent<
            (validationProps: ValidationErrorProps<SubT, V>) => JSX.Element
          >;
          Dropdown: React.MemoExoticComponent<(validationProps: TypedDropdownProps<SubT, V>) => JSX.Element>;
          Radio: React.MemoExoticComponent<(validationProps: TypedRadioProps<SubT, V>) => JSX.Element>;
        } => {
          const combinedKeyPath = [prefixKeyPath, field].filter(Boolean).join(".");

          // cheating with untyped because subpaths are actually impossible :(
          const getValue: () => SubT[V] = () =>
            get(store.getState().values, combinedKeyPath, undefined) as SubT[V];
          const getTouched: () => boolean = () => {
            const value = get(store.getState().touched, combinedKeyPath, undefined);
            return value as boolean;
          };
          const getError: () => FormikErrors<SubT>[V] = () =>
            get(store.getState().errors, combinedKeyPath, undefined) as SubT[V];

          const sentenceCasedField = camelCaseToSentence(field);

          const Input = ({
            label,
            radioId,
            placeholder,
            className,
            suffix,
            min,
            max,
            type: inputType,
            ...inputProps
          }: TypedInputProps<SubT, V>) => {
            const [value, setValue] = useState<SubT[V]>(getValue());
            const [hasError, setHasError] = useState<boolean>(!!getError());
            const [isDirty, setIsDirty] = useState<boolean>(!!getTouched());

            const finalValue =
              value || // including fallbacks to avoid console warning
              (inputType === "checkbox" ? false : inputType === "number" ? 0 : "");

            const finalPlaceholder = useMemo(
              () => (inputType === "checkbox" ? undefined : placeholder || sentenceCasedField),
              [placeholder]
            );

            const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value as SubT[V];

              setFieldValue(combinedKeyPath, newValue);
              setValue(newValue);
            };

            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setValue(getValue())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setHasError(!!getError())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setIsDirty(!!getTouched())),
              []
            );

            return (
              <InputElement
                label={label}
                id={radioId || combinedKeyPath}
                name={combinedKeyPath}
                placeholder={finalPlaceholder}
                type={inputType}
                onChange={handleChange}
                onBlur={handleBlur}
                suffix={suffix}
                min={min}
                max={max}
                className={cx(className, {
                  "form-error": hasError,
                  "form-dirty": isDirty,
                })}
                // TODO: figure out how to now use "any" here
                value={inputType === "checkbox" ? (undefined as any) : finalValue}
                checked={inputType === "checkbox" ? finalValue : (undefined as any)}
                error={(getError() || "") as string}
                {...inputProps}
              />
            );
          };

          const TextArea = ({ label, placeholder, className, ...textAreaProps }: TypedTextAreaProps<SubT>) => {
            const [value, setValue] = useState<SubT[V]>(getValue());
            const [hasError, setHasError] = useState<boolean>(!!getError());
            const [isDirty, setIsDirty] = useState<boolean>(!!getTouched());

            const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
              const newValue = event.target.value as SubT[V];

              setFieldValue(combinedKeyPath, newValue);
              setValue(newValue);
            };

            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setValue(getValue())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setHasError(!!getError())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setIsDirty(!!getTouched())),
              []
            );

            return (
              <TextAreaElement
                label={label}
                name={combinedKeyPath}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                className={cx(className, {
                  "form-error": hasError,
                  "form-dirty": isDirty,
                })}
                value={value}
                error={(getError() || "") as string}
                {...textAreaProps}
              />
            );
          };

          const Label = ({
            // useCallback(
            children,
            radioId,
            className,
            ...labelProps
          }: TypedLabelProps<SubT, V>) => {
            const [hasError, setHasError] = useState<boolean>(!!getError());
            const [isDirty, setIsDirty] = useState<boolean>(!!getTouched());

            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setHasError(!!getError())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setIsDirty(!!getTouched())),
              []
            );

            return (
              <label
                htmlFor={radioId || combinedKeyPath}
                className={cx(className, {
                  "form-error": hasError,
                  "form-dirty": isDirty,
                })}
                {...labelProps}
              >
                {children}
              </label>
            );
          };

          const ValidationError = (props: ValidationErrorProps<SubT, V>) => {
            const [error, setError] = useState<FormikErrors<SubT>[V]>(getError());

            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => {
                  const errorFromState = getError();
                  setError(
                    typeof errorFromState === "string"
                      ? (errorFromState.replaceAll(field, sentenceCasedField) as any)
                      : errorFromState
                  );
                }),
              []
            );

            return <div {...props}>{String(error ?? "")}</div>;
          };

          const Radio = ({
            className,
            label,
            buttons,
            onToggle,
            disabled,
            ...props
          }: TypedRadioProps<SubT, V>) => {
            const [value, setValue] = useState<SubT[V]>(getValue());
            const [hasError, setHasError] = useState<boolean>(!!getError());
            const [isDirty, setIsDirty] = useState<boolean>(!!getTouched());

            const onChangeWrapped = useCallback(
              (newValue: any[]) => {
                const newValueFirst = newValue[0];
                return handleChange({
                  target: { name: combinedKeyPath, value: newValueFirst as any },
                  currentTarget: {
                    name: combinedKeyPath,
                    value: newValueFirst as any,
                  },
                } as React.ChangeEvent<HTMLInputElement>);
              },
              [handleChange, name]
            );

            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setValue(getValue())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setHasError(!!getError())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setIsDirty(!!getTouched())),
              []
            );

            return (
              <RadioElement
                label={label || ""}
                buttons={buttons}
                onChange={onChangeWrapped}
                className={cx(className, {
                  "form-error": hasError,
                  "form-dirty": isDirty,
                })}
                value={value}
                onToggle={onToggle}
                disabled={disabled}
                {...props}
              />
            );
          };

          const Dropdown = ({ options, className, label, ...props }: TypedDropdownProps<SubT, V>) => {
            const [value, setValue] = useState<SubT[V]>(getValue());
            const [hasError, setHasError] = useState<boolean>(!!getError());
            const [isDirty, setIsDirty] = useState<boolean>(!!getTouched());

            const onChangeWrapped = useCallback(
              newValue => {
                const eventValue = numericRegexTest.test(newValue) ? Number(newValue) : newValue;
                return handleChange({
                  target: { name: combinedKeyPath, value: eventValue },
                  currentTarget: { name: combinedKeyPath, value: eventValue },
                } as React.ChangeEvent<HTMLInputElement>);
              },
              [handleChange, name]
            );

            const onBlurWrapped = useCallback(() => {
              const eventValue = numericRegexTest.test(value) ? Number(value) : value;
              return handleBlur({
                target: { name: combinedKeyPath, value: eventValue as any },
                currentTarget: { name: combinedKeyPath, value: eventValue as any },
              } as React.FocusEvent<HTMLInputElement>);
            }, [handleBlur, name]);

            useEffect(() => store.subscribe(() => setValue(getValue())), []);
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setHasError(!!getError())),
              []
            );
            useEffect(
              () =>
                // subscribing to redux returns unsubscribing function
                store.subscribe(() => setIsDirty(!!getTouched())),
              []
            );

            return (
              <DropdownElement
                {...props}
                className={cx(className, {
                  "form-error": hasError,
                  "form-dirty": isDirty,
                })}
                value={value}
                label={label || ""}
                options={options}
                onChange={onChangeWrapped}
                onBlur={onBlurWrapped}
              />
            );
          };

          return {
            Input: React.memo(Input),
            TextArea: React.memo(TextArea),
            Label: React.memo(Label),
            ValidationError: React.memo(ValidationError),
            Dropdown: React.memo(Dropdown),
            Radio: React.memo(Radio),
          };
        }, [field]),
    [store, handleChange, handleBlur]
  );
}
